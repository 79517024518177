import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {ProductWidgetApp} from './ProductWidgetApp';
import {bootstrapApp} from '../../commons/bootstrap';
import {PRODUCT_WIDGET_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles';

export const component = withSentryErrorBoundary(bootstrapApp(ProductWidgetApp, withLeanStyles), {
  dsn: PRODUCT_WIDGET_DSN,
  config: {environment: 'Native Component'},
});

import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import s from './OutOfStock.scss';

export enum DataHook {
  Root = 'product-widget-OutOfStock',
}

export interface IOutOfStockProps extends IProvidedGlobalProps, IProvidedTranslationProps {}

class OutOfStockComp extends React.Component<IOutOfStockProps> {
  public render(): React.ReactNode {
    const {t} = this.props;
    return (
      <div data-hook={DataHook.Root} className={s.outOfStock}>
        {t('OUT_OF_STOCK')}
      </div>
    );
  }
}

export const OutOfStock = withGlobalProps(withTranslations('globals.texts')(OutOfStockComp));

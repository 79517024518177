import * as React from 'react';
import {ISantaProps, IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: GlobalProps;
}

type PickedHostKeys =
  | 'experiments'
  | 'handleAddToCart'
  | 'hasDiscount'
  | 'horizontalLayout'
  | 'htmlTags'
  | 'isDisabled'
  | 'isInteractive'
  | 'isMobile'
  | 'isSEO'
  | 'isRTL'
  | 'navigate'
  | 'priceBreakdown'
  | 'product'
  | 'productPageUrl'
  | 'shouldRenderPriceRange'
  | 'shouldShowAutomaticDiscountDataOnProductWidget'
  | 'shouldFocusAddToCartButton'
  | 'clickShippingInfoLinkSf'
  | 'texts'
  | 'widgetPreset';

export type GlobalProps = Pick<IPropsInjectedByViewerScript & ISantaProps, PickedHostKeys> &
  Pick<IHostProps, 'dimensions' | 'style'>;

export function withGlobalPropsProvider(Component) {
  const WrappedComponent = (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {
      experiments,
      handleAddToCart,
      hasDiscount,
      horizontalLayout,
      htmlTags,
      isDisabled,
      isInteractive,
      isMobile,
      isRTL,
      isSEO,
      navigate,
      priceBreakdown,
      product,
      productPageUrl,
      shouldFocusAddToCartButton,
      clickShippingInfoLinkSf,
      style,
      texts,
      widgetPreset,
      shouldRenderPriceRange,
      shouldShowAutomaticDiscountDataOnProductWidget,
      ...passThroughProps
    } = props;
    const {dimensions} = props.host;
    return (
      <PropsContext.Provider
        value={{
          globals: {
            dimensions,
            experiments,
            handleAddToCart,
            hasDiscount,
            horizontalLayout,
            htmlTags,
            isDisabled,
            isInteractive,
            isMobile,
            isRTL,
            isSEO,
            navigate,
            priceBreakdown,
            product,
            productPageUrl,
            shouldFocusAddToCartButton,
            clickShippingInfoLinkSf,
            style,
            texts,
            widgetPreset,
            shouldRenderPriceRange,
            shouldShowAutomaticDiscountDataOnProductWidget,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  WrappedComponent.displayName = `withGlobalPropsProvider(${Component.displayName})`;

  return WrappedComponent;
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  const WrappedComponent = (props) => {
    return <PropsContext.Consumer>{(globalProps) => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  WrappedComponent.displayName = `withGlobalProps(${Component.displayName})`;

  return WrappedComponent;
}

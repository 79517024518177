/* eslint-disable @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires */
//*** -- Keep `require` and `module.exports` over `import` for Santa Worker (Felix) -- ***

const {component: noCssComponent} = require('./ProductWidgetAppConfigNoCss');

module.exports = {
  default: {
    component: noCssComponent,
  },
};

/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NoProductProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const NoProduct: React.FC<NoProductProps> = ({size, ...props}) => (
  <svg viewBox="0 0 153 204" fill="currentColor" width={ size || "153" } height={ size || "204" } {...props}>
    <defs/>
    <g id="illustration-copy">
      <ellipse id="ellipse-1" cx="77" cy="197" rx="77" ry="8" fill="currentColor" />
      <path d="M29.454,115.246 C29.454,115.246 123.733,115.246 123.733,115.246 C123.733,115.246 138.697,124.277 138.697,124.277 C138.697,124.277 11.496,124.277 11.496,124.277 C11.496,124.277 29.454,115.246 29.454,115.246 Z"
        id="path-1" className="cls-4" fillRule="evenodd" />
      <path id="rect-1" d="M27 116H123V169H27z" className="cls-5" />
      <path d="M11.500,124.312 C11.500,124.312 60.906,124.312 60.906,124.312 C60.906,124.312 60.906,199.531 60.906,199.531 C60.906,199.531 11.500,199.531 11.500,199.531 C11.500,199.531 11.500,124.312 11.500,124.312 ZM138.719,199.531 C138.719,199.531 92.312,199.531 92.312,199.531 C92.312,199.531 92.312,124.312 92.312,124.312 C92.312,124.312 138.719,124.312 138.719,124.312 C138.719,124.312 138.719,199.531 138.719,199.531 Z"
        id="path-2" className="cls-7" fillRule="evenodd" />
      <path id="rect-2" d="M60 124H93V200H60z" className="cls-8" />
      <path d="M6.582,66.066 C6.582,66.066 138.122,101.515 138.122,101.515 C138.122,101.515 121.730,104.888 121.730,104.888 C121.730,104.888 19.100,77.231 19.100,77.231 C19.100,77.231 6.582,66.066 6.582,66.066 Z"
        id="path-3" className="cls-4" fillRule="evenodd" />
      <path d="M21.037,69.962 C21.037,69.962 123.667,97.619 123.667,97.619 C123.667,97.619 121.730,104.888 121.730,104.888 C121.730,104.888 19.100,77.231 19.100,77.231 C19.100,77.231 21.037,69.962 21.037,69.962 Z"
        id="path-4" className="cls-5" fillRule="evenodd" />
      <path d="M16.265,29.722 C16.265,29.722 147.804,65.170 147.804,65.170 C147.804,65.170 138.122,101.515 138.122,101.515 C138.122,101.515 6.582,66.066 6.582,66.066 C6.582,66.066 16.265,29.722 16.265,29.722 Z"
        id="path-5" className="cls-7" fillRule="evenodd" />
      <path d="M66.857,43.356 C66.857,43.356 98.658,51.926 98.658,51.926 C98.658,51.926 88.975,88.270 88.975,88.270 C88.975,88.270 57.174,79.700 57.174,79.700 C57.174,79.700 66.857,43.356 66.857,43.356 Z"
        id="path-6" className="cls-8" fillRule="evenodd" />
      <path d="M73.449,39.859 C69.995,39.513 63.007,35.346 58.539,31.990 C56.512,30.470 49.189,22.943 49.801,14.146 C50.347,6.295 54.392,1.779 62.406,0.252 C66.524,-0.534 70.729,0.754 70.729,0.754 C70.729,0.754 76.282,2.079 78.844,4.960 C86.584,13.662 85.557,19.315 84.688,24.448 C83.836,29.485 85.443,34.273 84.226,36.633 C84.226,36.633 87.082,37.402 87.082,37.402 C87.352,34.510 90.521,31.756 93.307,28.503 C96.779,24.448 102.946,22.742 102.946,22.742 C102.946,22.742 107.767,20.484 111.842,20.212 C118.311,19.370 125.189,23.517 126.722,25.661 C129.410,29.422 132.427,35.720 132.427,35.720 C132.427,35.720 133.033,41.533 130.493,45.179 C127.968,48.807 125.458,49.226 121.360,51.097 C117.188,53.001 111.841,52.429 104.716,51.278 C99.735,50.472 95.030,50.453 92.707,47.943 M60.755,27.572 C63.816,31.118 73.811,38.413 73.811,38.413 C73.811,38.413 73.838,38.440 73.840,38.443 C73.840,38.443 75.026,34.153 75.026,34.153 C75.026,34.153 79.831,35.448 79.831,35.448 C79.882,33.493 81.006,21.612 78.559,14.292 C76.226,7.312 67.532,3.703 60.658,6.138 C54.086,8.465 50.652,15.867 60.755,27.572 ZM90.996,38.458 C90.996,38.458 94.501,39.402 94.501,39.402 C94.501,39.402 93.180,45.691 93.180,45.691 C94.099,45.550 107.369,47.505 111.711,47.243 C124.313,46.483 128.261,38.855 125.850,32.979 C121.681,22.818 113.975,24.116 105.562,28.532 C90.797,36.284 91.505,38.944 90.996,38.458 Z"
        id="path-7" className="cls-8" fillRule="evenodd" />
      <path d="M74.292,32.894 C74.292,32.894 95.974,38.737 95.974,38.737 C95.974,38.737 92.876,50.367 92.876,50.367 C92.876,50.367 71.193,44.524 71.193,44.524 C71.193,44.524 74.292,32.894 74.292,32.894 Z"
        id="path-8" className="cls-5" fillRule="evenodd" />
    </g>
  </svg>
);
NoProduct.displayName = 'NoProduct';
/* tslint:enable */
/* eslint-enable */

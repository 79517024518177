import React from 'react';
import s from './ProductDiscountName.scss';

export interface ProductDiscountNameProps {
  name: string;
}

export enum DataHook {
  Root = 'product-discount-name',
}

export class ProductDiscountName extends React.Component<ProductDiscountNameProps> {
  public render(): React.ReactNode {
    return (
      <h1 data-hook={DataHook.Root} className={s.productDiscountName}>
        {this.props.name}
      </h1>
    );
  }
}

import * as React from 'react';
import classnames from 'classnames';
import {OldProductImage as OldImage} from '@wix/wixstores-client-common-components/dist/es/src/OldProductImage/OldProductImage';
import {IProductWidgetDTO} from '../../../types/app-types';
import {
  adjustImageDimensionToContainer,
  getMediaUrl,
  isSantaFullWidthMode,
} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {IProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import s from '../ProductImage.scss';
import {getProductWidgetSettingsFromProps} from '../../../commons/styleParamsService';
import {ProductRibbon} from '../../ProductRibbon/ProductRibbon';

export interface IProductImageProps {
  productName: string;
  imageMode: number;
  mountedToDOM: boolean;
  media: IProductWidgetDTO['media'][0];
}

export enum DataHook {
  Root = 'product-widget-image-component',
  ImageContainer = 'product-widget-image-container',
  Image = 'product-widget-image',
}

export interface IProductImageState {
  width: number;
  height: number;
  imageLoaded: boolean;
}

class ProductImageComp extends React.Component<IProductImageProps & IProvidedGlobalProps, IProductImageState> {
  public state = {imageLoaded: false, height: undefined, width: undefined};
  private divRef: any;

  private getContainerDOMDimensions(): {width: number; height: number} {
    const {
      // layoutDimensions,
      globals: {
        dimensions: {height, width},
      },
      mountedToDOM,
    } = this.props;

    if (mountedToDOM) {
      return {height: this.state.height || height, width: this.state.width};
    }

    if (isSantaFullWidthMode(this.props.globals)) {
      return {width: 980, height};
    }

    return {height, width};
  }

  private readonly onImageLoad = () => {
    this.setState({imageLoaded: true});
  };

  private getMediaSrc(isSSR = false): string {
    const {media, imageMode} = this.props;
    const containerDomDimensions = this.getContainerDOMDimensions();
    const mediaDimensions = adjustImageDimensionToContainer(media, containerDomDimensions, imageMode);
    /* istanbul ignore next: todo */
    return getMediaUrl(media, mediaDimensions || media, {
      isSSR,
      imageMode,
    });
  }

  private readonly getImageContainerStyle = () => {
    const {imageLoaded} = this.state;
    const {isSEO} = this.props.globals;

    return imageLoaded || isSEO
      ? {}
      : {
          backgroundImage: `url(${this.getMediaSrc(true)})`,
        };
  };

  public componentDidMount(): void {
    this.setState({
      height: this.divRef.clientHeight,
      width: this.divRef.clientWidth,
    });
  }

  public render(): React.ReactNode {
    const {
      productName,
      imageMode,
      mountedToDOM,
      globals: {product, horizontalLayout, isSEO, isMobile},
    } = this.props;
    const isNeedToBeMountedToDOM = mountedToDOM || isSEO;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const wrapperClassNames = classnames(s.productImage, {
      [s.horizontal]: horizontalLayout,
      [s.vertical]: !horizontalLayout,
      [s.hiddenOverflow]: settings.extendOnHoverToggle,
    });
    const imageClassNames = classnames({
      [s.productImageMobile]: isMobile,
      [s.zoomOnHover]: settings.extendOnHoverToggle,
    });
    return (
      <div data-hook={DataHook.Root} className={wrapperClassNames} ref={(r) => (this.divRef = r)}>
        {product.ribbon ? <ProductRibbon /> : null}
        <OldImage
          imageMode={imageMode}
          productName={productName}
          mountedToDOM={isNeedToBeMountedToDOM}
          imageLoaded={this.onImageLoad}
          style={this.getImageContainerStyle()}
          className={imageClassNames}
          containerDataHook={DataHook.ImageContainer}
          imgDataHook={DataHook.Image}
          src={this.getMediaSrc()}
        />
      </div>
    );
  }
}

export const ProductImage = withGlobalProps(ProductImageComp);

import * as React from 'react';
import classnames from 'classnames';
import s from '../ProductImage.scss';
import {DEFAULT_IMAGE_DEFAULT_SIZE} from '../../../constants';
import {DefaultProductImage} from '@wix/wixstores-client-common-components/dist/es/src/icons/dist';

export enum DataHook {
  emptyImage = 'product-empty-image',
}

export interface IEmptyProductImageProps {
  horizontalLayout: boolean;
  extendOnHoverToggle: boolean;
}

export const EmptyProductImage: React.FunctionComponent<IEmptyProductImageProps> = ({
  horizontalLayout,
  extendOnHoverToggle,
}) => {
  const renderDefaultProductImage = () => {
    return (
      <div
        data-hook={DataHook.emptyImage}
        className={classnames(s.emptyImage, {
          [s.horizontal]: horizontalLayout,
          [s.vertical]: !horizontalLayout,
          [s.hiddenOverflow]: extendOnHoverToggle,
        })}>
        <div className={classnames(s.centerContent, {[s.zoomOnHover]: extendOnHoverToggle})}>
          <DefaultProductImage size={DEFAULT_IMAGE_DEFAULT_SIZE} />
        </div>
      </div>
    );
  };

  return renderDefaultProductImage();
};

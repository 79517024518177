import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductImage} from './ProductImage/ProductImage';
import {getProductWidgetSettingsFromProps} from '../../commons/styleParamsService';
import {ImageScaling} from '../../commons/enums';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {EmptyProductImage} from './EmptyProductImage/EmptyProductImage';

export type IMediaAreaProps = IProvidedGlobalProps;

interface IMediaAreaState {
  mountedToDOM: boolean;
}

class MediaAreaComp extends React.Component<IMediaAreaProps, IMediaAreaState> {
  public state: IMediaAreaState = {
    mountedToDOM: false,
  };

  public componentDidMount(): void {
    this.setState({
      mountedToDOM: true,
    });
  }

  public render(): React.ReactNode {
    const {
      globals: {product, horizontalLayout},
    } = this.props;
    const {mountedToDOM} = this.state;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const imageMode = settings.imageScaling === ImageScaling.FIT ? ImageModeValues.FIT : ImageModeValues.CROP;

    if (!product.media?.[0]) {
      return (
        <EmptyProductImage horizontalLayout={horizontalLayout} extendOnHoverToggle={settings.extendOnHoverToggle} />
      );
    }

    return (
      <ProductImage
        media={product.media[0]}
        productName={product.name}
        imageMode={imageMode}
        mountedToDOM={mountedToDOM}
      />
    );
  }
}

export const MediaArea = withGlobalProps(MediaAreaComp);
